import { ComponentActions } from "@dk/client/bootstrap";
import { forTarget, forTargets } from "@dk/client/content";
import { mount } from '@dk/client/mount'
import { RPCClientInterface } from "@dk/client/rpcClient";
import { PersistentRecord } from "@dk/client/store";
import { BehaviorSubject, fromEvent } from "rxjs";

type VideoPlayerProps = {
  RPCClient: RPCClientInterface,
  votes$: PersistentRecord<Votes>,
  myVotes$: PersistentRecord<string[]>
}

export default async function (root: RootElement, props: VideoPlayerProps): Promise<ComponentActions> {
  const currentVideo$ = new BehaviorSubject<string | null>(null)
  const currentCreator$ = new BehaviorSubject<string>('')
  const currentVotes$ = new BehaviorSubject<number>(0)

  let player: HTMLIFrameElement

  const createPlayer = (videoId: string) => {
    player = document.createElement('iframe')
    player.src = `https://customer-yccfy418px1f80n5.cloudflarestream.com/${videoId}/iframe`
    player.allow = 'auto-play; fullscreen;'
    player.style.width = '100%'
    player.style.height = '460px'
    player.style.maxHeight = '100%'
    player.style.border = 'none'
  }

  const setCreatorName = () => {
    forTarget<HTMLDivElement>(root, 'creator-name')!.textContent = currentCreator$.getValue()
  }

  const setCurrentVotes = () => {
    const videoVotes = props.votes$.getValue()?.[currentVideo$.getValue() || '']
    forTarget<HTMLDivElement>(root, 'modal-video-upvote-count')!.textContent = videoVotes?.toString() || '0'
  }

  const destroyPlayer = () => {
    player?.remove()
  }

  const swapVideo = (uid: string | null) => {
    if (!uid) return destroyPlayer()

    createPlayer(uid)
    forTarget<HTMLDivElement>(root, 'video-frame')?.appendChild(player)
  }

  const toggleUpvoteButton = () => {
    const votes = props.myVotes$.getValue()
    const button = forTarget<HTMLButtonElement>(root, 'modal-upvote-video')!

    if (votes?.includes(currentVideo$.getValue()!)) {
      button.classList.add('disabled')
      button.classList.remove('zoom')
    } else {
      button.classList.remove('disabled')
      button.classList.add('zoom')
    }
  }

  function registerVideoPlayerClickListeners () {
    const thumbnails = forTargets<HTMLElement>(root, 'video-thumbnail')
    const modalBackground = forTarget<HTMLElement>(root, 'modal-background')
    const modalClose = forTarget<HTMLElement>(root, 'modal-close')

    thumbnails.forEach((thumbnail) => {
      fromEvent(thumbnail!, 'click').subscribe((e) => {
        const target = e.currentTarget as HTMLElement
        const videoID = target.dataset.videoId || ''
        const videoCreator = target.dataset.videoCreator || ''
        const videoVotes = props.votes$.getValue()!

        currentVideo$.next(videoID)
        currentCreator$.next(videoCreator)
        currentVotes$.next(videoVotes[videoID] || 0)
      })
    })

    fromEvent(modalBackground!, 'click').subscribe(() => {
      currentVideo$.next(null)
    })

    fromEvent(modalClose!, 'click').subscribe(() => {
      currentVideo$.next(null)
    })
  }

  const registerUpvoteListener = () => {
    fromEvent(forTarget<HTMLLinkElement>(root, 'modal-upvote-video')!, 'click').subscribe((e) => {
      const videoID = currentVideo$.getValue()
      if (!videoID) return

      props.RPCClient<{ id: string, count: number }>('video.upvote', { videoID }).subscribe(({ response }) => {
        const counter = root.querySelector<HTMLSpanElement>(`[dk-target="score-bubble"][data-video-id="${videoID}"]`)!
        const myVotes = props.myVotes$.getValue() as string[]
        counter.textContent = response.data.count.toString()

        props.myVotes$.next([...myVotes, videoID])
      })
    })
  }

  return {
    watch: [{
      target: currentVideo$,
      actions: [swapVideo, toggleUpvoteButton]
    }, {
      target: currentCreator$,
      actions: [setCreatorName]
    }, {
      target: currentVotes$,
      actions: [setCurrentVotes]
    }, {
      target: props.myVotes$,
      actions: [toggleUpvoteButton]
    }, {
      target: props.votes$,
      actions: [setCurrentVotes]
    }],
    start: mount(root,
      registerVideoPlayerClickListeners,
      registerUpvoteListener,
    )
  }
}

