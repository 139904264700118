import { ComponentActions } from '@dk/client/bootstrap'
import { forTarget, forTargets } from '@dk/client/content'
import { mount } from '@dk/client/mount'
import { RPCClientInterface } from '@dk/client/rpcClient'
import { PersistentRecord } from '@dk/client/store'
import { fromEvent } from 'rxjs'

type VotingProps = {
  RPCClient: RPCClientInterface
  scores$: PersistentRecord<Votes>
  myVotes$: PersistentRecord<string[]>
}

export default async function (root: RootElement, props: VotingProps): Promise<ComponentActions> {
  const { RPCClient, myVotes$ } = props

  /**
   * Sends an upvote request to the server and returns the new score
   *
   * @param videoID
   */
  const upvoteVideo = (videoID: string) => {
    RPCClient<{ id: string, count: number }>('video.upvote', { videoID }).subscribe(({ response }) => {
      const counter = root.querySelector<HTMLSpanElement>(`[dk-target="score-bubble"][data-video-id="${videoID}"]`)!
      myVotes$.next([...myVotes$.getValue()!, videoID])
    })
  }

  /**
   * Watches for upvote button clicks and calls the upvoteVideo function
   */
  const registerUpvoteClickListeners = () => {
    const targets = forTargets<HTMLLinkElement>(root, 'upvote-button')

    for (const target of targets) {
      fromEvent(target as HTMLElement, 'click').subscribe((e) => {
        const target = e.currentTarget as HTMLElement
        const card = target.closest('[dk-target="video-card"]') as HTMLElement
        const videoID = forTarget<HTMLDivElement>(card, 'video-thumbnail')?.dataset.videoId

        videoID && upvoteVideo(videoID)
      })
    }
  }


  return {
    start: mount(root, registerUpvoteClickListeners),
  }
}
