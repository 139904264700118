import { ComponentActions } from "@dk/client/bootstrap"
import { forTarget, forTargets } from "@dk/client/content"
import { mount } from "@dk/client/mount"
import { PersistentRecord } from "@dk/client/store"
import { fromEvent, BehaviorSubject } from "rxjs"

type Props = {
  myViews$: PersistentRecord<string[]>,
}

export default async function Views (root: RootElement, props: Props): Promise<ComponentActions> {
  const currentView$ = new BehaviorSubject<string>('view-all')

  const updateViews = (views: string[]) => {
    views.forEach((view) => {
      const thumbnail = document.querySelector(`[dk-target="video-thumbnail"][data-video-id="${view}"]`) as HTMLDivElement
      const container = thumbnail.closest('[dk-target="video-card"]') as HTMLDivElement

      if (thumbnail) {
        forTarget<HTMLDivElement>(container, 'viewed-icon')!.style.display = 'flex'
      }
    })
  }

  function registerVideoPlayerClickListeners () {
    const thumbnails = forTargets<HTMLElement>(root, 'video-thumbnail')

    thumbnails.forEach((thumbnail) => {
      fromEvent(thumbnail!, 'click').subscribe((e) => {
        const target = e.currentTarget as HTMLElement
        const videoID = target.dataset.videoId || ''
        const myViews = props.myViews$.getValue()!

        props.myViews$.next([...myViews, videoID])
      })
    })
  }

  function handleFilters () {
    const filters = forTargets<HTMLDivElement>(root, 'filter-button')

    filters.forEach((filter) => {
      fromEvent(filter!, 'click').subscribe((e) => {
        const target = e.currentTarget as HTMLButtonElement

        filters.forEach((filter) => filter?.classList.remove('active'))
        target.classList.add('active')

        const action = target.dataset.action || ''
        currentView$.next(action)

      })
    })
  }

  const handleFilterChange = () => {
    const action = currentView$.getValue()

    if (action === 'view-all') {
      forTargets<HTMLDivElement>(root, 'video-card').forEach((card) => {
        card?.classList.remove('hidden')
      })
    } else {
      forTargets<HTMLDivElement>(root, 'video-card').forEach((card) => {
        card?.classList.add('hidden')
      })

      forTargets<HTMLDivElement>(root, 'video-card').forEach((card) => {
        const videoID = forTarget<HTMLDivElement>(card!, 'video-thumbnail')?.dataset.videoId || ''
        const myViews = props.myViews$.getValue()!

        myViews.includes(videoID) || card?.classList.remove('hidden')
      })
    }
  }

  return {
    watch: [
      { target: props.myViews$, actions: [updateViews, handleFilterChange] },
      { target: currentView$, actions: [handleFilterChange] },
    ],
    start: mount(root, registerVideoPlayerClickListeners, handleFilters),
  }
}
