import { ComponentActions } from '@dk/client/bootstrap'
import { forTarget, forTargets } from '@dk/client/content'
import { mount } from '@dk/client/mount'
import { RPCClientInterface } from '@dk/client/rpcClient'
import { PersistentRecord } from '@dk/client/store'

const MAX_VOTES = 3

type VotingProps = {
  RPCClient: RPCClientInterface
  votes$: PersistentRecord<Votes>
  myVotes$: PersistentRecord<string[]>
}

export default async function (root: RootElement, props: VotingProps): Promise<ComponentActions> {
  const updateScores = (scores: number) => {
    props.RPCClient<Votes>('video.votes', {})
      // .pipe(delay(500), repeat())
      .subscribe(({ response }) => {
        props.votes$.next(response.data || [])
      })
  }

  const setVoteValues = (votes: Votes) => {
    forTargets<HTMLDivElement>(root, 'score-bubble').forEach((target) => {
      if (!target) return
      const videoID = target.dataset.videoId!
      const currentVotes = votes[videoID as any] || 0

      if (currentVotes) {
        target.textContent = currentVotes.toString()
      }
    })
  }

  const stopAtMaxVotes = (votes: Votes) => {
    const myVotes = props.myVotes$.getValue()!
    const uniqueVotes = Array.from(new Set(myVotes)).length

    if (uniqueVotes >= MAX_VOTES) {
      const buttons = root.querySelectorAll('[dk-target="upvote-button"]') as NodeList
      forTarget<HTMLLinkElement>(root, 'modal-upvote-video')!.style.display = 'none'

      Array.from(buttons).forEach((button) => {
        const target = (button as HTMLLinkElement)
        const videoID = target.querySelector<HTMLDivElement>('[data-upvote-id]')?.dataset.upvoteId || ''

        if (!myVotes.includes(videoID)) {
          target.style.display = 'none'
        }
      })
    }
  }

  return {
    watch: [
      { target: props.votes$, actions: [setVoteValues] },
      { target: props.myVotes$, actions: [stopAtMaxVotes] },
    ],
    start: mount(root, updateScores),
  }
}
